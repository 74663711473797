.basic-plan {
    background: linear-gradient(to bottom, var(--primary-gradient-color), #909ab8);
}

.standrad-plan {
    background: linear-gradient(to bottom, var(--ternary-gradient-color), #909ab8)
}

.premium-plan {
    background: linear-gradient(to bottom, var(--secondary-gradient-color), #909ab8);
}

.login-backgoud {
    border-radius: '56px' !important;
    padding: '0.3rem' !important;
    background: linear-gradient(180deg, var(--primary-gradient-color) 30%, var(--secondary-gradient-color) 70%);
}
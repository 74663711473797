:root {
    font-family: 'Montserrat',
        'Poppins',
        Roboto,
        -apple-system,
        system-ui,
        BlinkMacSystemFont,
        'Segoe UI',
        'Helvetica Neue',
        Arial,
        sans-serif;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-variation-settings: normal;
    --font-family: "Helvetica Neue", sans-serif;
    --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    --surface-a: #ffffff;
    --surface-b: #f9fafb;
    --surface-c: #f3f4f6;
    --surface-d: #e5e7eb;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #203570;
    --text-color-secondary: #1d3065;
    --primary-color: #e8ae41;
    --primary-color-text: #ffffff;
    --surface-0: #ffffff;
    --surface-50: #f9fafb;
    --surface-100: #f3f4f6;
    --surface-200: #e5e7eb;
    --surface-300: #d1d5db;
    --surface-400: #9ca3af;
    --surface-500: #6b7280;
    --surface-600: #4b5563;
    --surface-700: #374151;
    --surface-800: #1f2937;
    --surface-900: #111827;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: #f9fafb;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dfe7ef;
    --surface-hover: #f6f9fc;
    --focus-ring: 0 0 0 0.2rem #bfdbfe;
    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: #eff6ff;
    --highlight-text-color: #d19d3b;
    --heading-text-color: #1a2a5a;
    --primary-gradient-color: #e8ae41;
    --secondary-gradient-color: #bd2a49;
    --ternary-gradient-color: #de6f2f;
    --primary-gradient-color-light: #f5d18a;
    --secondary-gradient-color-light: #e1737e;
    --ternary-gradient-color-light: #f4a97a;
    color-scheme: light;
}
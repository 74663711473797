@import "../../style/variables.css";

.card-bg {
    background: linear-gradient(180deg, var(--primary-gradient-color) 30%, var(--ternary-gradient-color) 70%);
}

.mg-card-footer button:first-child {
    background-color: var(--text-color-secondary);

}

.mg-card-footer button:nth-child(2) {
    background-color: var(--secondary-gradient-color);

}
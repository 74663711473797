@import "../../style/variables.css";


.primary {
    background-color: var(--primary-color);
}

.secondary {
    background-color: var(--secondary-gradient-color);
}

.quaternary {
    background-color: var(--ternary-gradient-color);
}
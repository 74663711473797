.grid-multi-select-wrapper {
  max-width: 300px !important;
  overflow: scroll;
}


.p-datatable-thead th {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.8px !important;
}

.p-datatable-tbody td {
  font-size: 13px;
  font-weight: 350;
  letter-spacing: 0.8px !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-justify: inter-ideograph;
}


.p-datatable .p-button {
  height: 35px;
  width: 30px;
}

.p-paginator {
  justify-content: end;
  font-size: 13px;
  font-weight: 300;
}

.p-paginator button {
  font-size: 13px;
}

.p-datatable .p-inputswitch {
  height: 1.5rem;
}
body {
  font-family: 'Montserrat',
    'Poppins',
    Roboto,
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    'Segoe UI',
    'Helvetica Neue',
    Arial,
    sans-serif;
  color: #203570;
  line-height: 1.5;
  letter-spacing: 0.2px;
  overflow-x: hidden;
  background-color: #f7f9fa;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


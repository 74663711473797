.image {
  display: flex !important;
  justify-content: center;
  align-items: center;
}


.card-comp {
    background-color: #fff;
    height:auto;
    padding: 1rem;
  }

.scroll-panell {
  max-height: 140px;
  overflow: auto;
  width: 100%;
}

.grid-containerr {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

@media (min-width: 600px) {
  .grid-containerr {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .grid-containerr {
    grid-template-columns: repeat(3, 1fr);
  }
} 
.video-container {
  object-fit: cover; 
}